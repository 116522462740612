.kasa-dropdown-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;

  @media screen and (max-width: 840px) {
    flex-direction: column;
    gap: 20px;
    margin-bottom: 27px;
  }
}

.kasa-collapse {
  border-radius: 10px;
  width: 40%;

  @media screen and (max-width: 840px) {
    width: 100%;
  }
}

.kasa-collapse-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ff6060;
  padding: 10px;
  border-radius: 10px;
  color: white;

  & h1 {
    font-size: 18px;
  }

  &.close img {
    transform: rotate(0deg);
    transition: transform 500ms ease-in-out;
  }

  &.open img {
    transform: rotate(-180deg);
    transition: transform 500ms ease-in-out;
  }
}

.kasa-collapse-content {
  box-sizing: content-box;
  background-color: #f6f6f6;
  color: #ff6060;
  border-radius: 10px;
  overflow: hidden;

  &.content-close {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s linear;

    & p,
    ul {
      padding: 20px;
    }
  }

  &.content-open {
    max-height: 500px;
    transition: max-height 0.5s linear;

    & p,
    ul {
      padding: 20px;
    }
  }

  ul {
    list-style: none;
  }
}

.kasa-about {
  width: 100%;
  margin-bottom: 30px;
}
