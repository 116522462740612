footer {
  background-color: black;
  width: 100%;
  color: white;
  text-align: center;
  padding-top: 66px;
  padding-bottom: 29px;
  max-height: 209px;
  margin-top: auto;

  & img {
    height: 39px;
    width: 122px;
    margin-bottom: 29px;
  }

  & p {
    height: 46px;
  }
}
