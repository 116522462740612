// HouseItem.scss

.kasa-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 50px;
  border-radius: 25px;
  background: #f6f6f6;
  justify-items: center;
  align-items: center;
  position: relative;
  padding: 50px;
  margin-bottom: 50px;

  @media screen and (max-width: 1000px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: 800px) {
    grid-template: none;
    grid-template-rows: 1fr;
    gap: 20px;
    margin: 22px 20px 50px 20px;
    padding: 0;
    background: none;
  }
}

.kasa-card {
  position: relative;
  overflow: hidden;
  aspect-ratio: 1/1;
}

.kasa-card img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
}

.kasa-text {
  font-size: 18px;
  width: 100%;
  height: 50%;
  position: absolute;
  bottom: 0;
  left: 0;
  color: white;
  border-radius: 10px;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(0, 0, 0, 0.5) 100%
  );

  & p {
    position: absolute;
    bottom: 20px;
    left: 20px;
    line-height: 142.6%;
  }
}
