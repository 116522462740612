.kasa-banner {
  width: 100%;
  height: 223px;
  display: flex;
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
  background-size: cover;
  background-position: center;
  border-radius: 25px;
  margin: 50px 0;
}

.kasa-slogan {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 48px;
  color: white;
}
