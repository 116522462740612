@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');

* {
    font-family: 'Montserrat';
    margin: 0;
    font-weight: 500;
}

body::-webkit-scrollbar{
    display: none;
}


.app {
    position: relative;
    display: flex;
    margin: auto;
    flex-direction: column;
    flex: 1;
    min-height: 100vh;
}

header, main {
    margin-left: 100px;
    margin-right: 100px;
}

@media screen and (max-width: 840px) {

    header, main {
        margin-left: 20px;
        margin-right: 20px;
    }
}