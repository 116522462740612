.kasa-sheet {
  justify-content: space-between;
  width: 100%;

  .kasa-title-tags {
    .kasa-title {
      color: #ff6060;
      margin-bottom: 20px;

      h1 {
        font-size: 36px;

        @media screen and (max-width: 840px) {
          font-size: 18px;
        }
      }
      p {
        font-size: 18px;

        @media screen and (max-width: 840px) {
          font-size: 14px;
        }
      }
    }

    @media screen and (max-width: 840px) {
      flex-direction: column;
      margin-bottom: 20px;
    }
  }

  .kasa-host-rating {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    .kasa-host {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      color: #ff6060;
      font-size: 18px;
      margin-bottom: 20px;

      @media screen and (max-width: 840px) {
        font-size: 12px;
      }

      img {
        margin-left: 10px;
        border-radius: 50%;
        width: 64px;
        height: 64px;

        @media screen and (max-width: 840px) {
          width: 32px;
          height: 32px;
        }
      }
    }

    @media screen and (max-width: 840px) {
      flex-direction: row;
      justify-content: space-between;

      .rating {
        order: -1;
      }
    }
  }
}

.kasa-info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;

  @media screen and (max-width: 840px) {
    flex-direction: column;
  }
}
