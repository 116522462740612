.kasa-banner {
  width: 100%;
  height: 223px;
  display: flex;
  border-radius: 25px;
  margin: 50px 0;
  position: relative;

  @media screen and (max-width: 1000px) {
    height: 170px;
  }

  & img {
    width: 100%;
    border-radius: 25px;
    object-fit: cover;
  }

  .kasa-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 223px;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 25px;
    display: flex;
    @media screen and (max-width: 1000px) {
      height: 170px;
    }
  }

  .kasa-slogan {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 48px;
    color: white;
    text-align: center;

    @media screen and (max-width: 1000px) {
      font-size: 36px;
      text-align: left;
    }

    @media screen and (max-width: 650px) {
      font-size: 24px;
      text-align: left;
    }
  }
}
