header {
  display: flex;
  font-weight: 500;
  justify-content: space-between;
  align-items: center;
  padding: 45px 0;

  @media screen and (max-width: 840px) {
    font-size: 12px;
    text-transform: uppercase;
  }

  img {
    width: 210px;
    height: 68px;

    @media screen and (max-width: 840px) {
      width: 145px;
      height: 47px;
    }
  }
}

.kasa-menu {
  display: flex;
  list-style: none;
  gap: 57px;

  a {
    color: #ff6060;
    font-size: 24px;
    text-decoration: none;

    &.active {
      text-decoration: underline;
    }
  }

  @media screen and (max-width: 840px) {
    gap: 27px;
  }
}
