.kasa-tags {
  display: flex;
  gap: 10px;
  align-items: center;

  @media screen and (max-width: 840px) {
    width: 84px;
  }
}

.kasa-tag {
  display: flex;
  justify-content: center;
  width: auto;
  padding: 3px 40px;
  background: #ff6060;
  color: #fff;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 142.6%;
  border-radius: 10px;

  @media screen and (max-width: 840px) {
    font-size: 10px;
    padding: 2px 30px;
  }
}
